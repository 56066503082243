<template>
  <div class="login-container" v-loading="loading">
    <div class="loginPanel">
      <div class="loginLeft">
        <div class="systemImage">
          <img src="/images/logo2.png" class="img" />
        </div>
        <div class="systemTitle">心体汇</div>
      </div>
      <div class="loginRight">
        <div class="loginTitle">欢迎登录</div>
        <el-form
          class="login-form"
          autoComplete="on"
          :model="loginForm"
          :rules="loginRules"
          ref="loginForm"
          label-position="left"
        >
          <el-form-item prop="username">
            <el-input
              name="username"
              type="text"
              v-model="loginForm.username"
              autoComplete="off"
              placeholder="请输入用户名"
            />
          </el-form-item>
          <el-form-item prop="password">
            <el-input
              name="password"
              type="password"
              v-model="loginForm.password"
              autoComplete="off"
              placeholder="请输入登录密码"
            />
          </el-form-item>
          <el-form-item prop="code">
            <div style="display: flex">
              <el-input
                v-model="loginForm.code"
                autoComplete="off"
                style="flex: 1"
                placeholder="请输入验证码，区分大小写"
              />
              <img
                :src="loginForm.codeSrc"
                @click="getValidateCode"
                style="width: 100px"
              />
            </div>
          </el-form-item>
          <el-button
            type="primary"
            style="width: 100%; margin-bottom: 30px"
            @click.native.prevent="handleLogin"
            >登录</el-button
          >
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import { login } from "@/api/setting/member";
import { getUserPageInfo, getVerifyCode } from "@/api/setting/member";
import { filterAsyncRouter } from "@/utils/asyncRoute";
import router from "@/router";
export default {
  name: "login",
  data() {
    const validatePassword = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请输入密码"));
      }
      if (value.length < 6) {
        callback(new Error("密码长度不得少于6位"));
      } else {
        callback();
      }
    };
    return {
      loginForm: {
        username: "",
        password: "",
        code: "",
        codeSrc: "",
      },
      loginRules: {
        username: { required: true, message: "请输入用户名", trigger: "null" },
        password: {
          required: true,
          trigger: "blur",
          validator: validatePassword,
        },
        code: {
          required: true,
          message: "请输入验证码",
        },
      },
      loading: false,
    };
  },
  methods: {
    getValidateCode() {
      let parma = {};
      getVerifyCode(parma).then((res) => {
        this.loginForm.codeSrc = "data:image/png;base64," + res.src;
      });
    },
    handleLogin() {
      var _this = this;
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          //客户端验证通过
          this.loading = true;
          let param = {
            username: this.loginForm.username,
            password: this.loginForm.password,
            code: this.loginForm.code,
          };
          login(param)
            .then((response) => {
              this.loading = false;
              if (response) {
                //登录成功
                localStorage.setItem("X-Token", response.token);
                localStorage.setItem("X-UserName", response.userName);
                localStorage.setItem("X-UserRealName", response.userRealName);
                localStorage.setItem("X-UserNickName", response.userNickName);
                localStorage.setItem("X-UserHeadImage", response.userHeadImage);
                localStorage.setItem("X-UserKey", response.userKey);
                localStorage.setItem("X-TenantName", response.tenantName);
                localStorage.setItem("X-TenantKey", response.tenantKey);
                localStorage.setItem("X-User", JSON.stringify(response.user));
                this.$store.dispatch("clearRouteHistory");
                this.$store.commit("userInfo", {
                  name: response.userName,
                  key: response.userKey,
                });
                this.$store.commit("userTenantInfo", {
                  name: response.tenantName,
                  key: response.tenantKey,
                });
                setTimeout(() => {
                  getUserPageInfo({}).then((r) => {
                    let base_router = JSON.stringify(r);
                    const filterAsyncRouters = filterAsyncRouter(
                      JSON.parse(base_router)
                    );
                    this.$store.commit("routers", filterAsyncRouters);
                    localStorage.setItem("X-routes", base_router);
                    router.addRoutes(filterAsyncRouters);
                    _this.$router.push({ path: "/" });
                  });
                }, 10);
              }
            })
            .catch((ex) => {
              this.msg.errorMsg(ex);
              _this.loading = false;
            });
        } else {
          setTimeout(() => {
            this.$refs.loginForm.clearValidate();
          }, 1000);
          return false;
        }
      });
    },
  },
  mounted() {
    this.$refs.loginForm.resetFields();
    this.getValidateCode();
  },
  destroyed() {},
};
</script>

<style rel="stylesheet/scss" lang="scss" scoped>
.login-container {
  height: 100vh;
  width: 100vw;
  background-image: url("../../assets/QJ6735925954.jpg");
  background-size: cover;
  background-position: center center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .loginPanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70vw;
    height: 50vh;

    .loginLeft {
      background-color: rgba(48, 64, 85, 0.3);
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .systemTitle {
        font-size: 60px;
        color: #fff;
      }
      .systemImage {
        margin-top: 20px;
        width: auto;
        // height: 100%;
        // width: 100%;
        .img {
          width: 200px;
          height: 100px;
        }
      }
    }
    .loginRight {
      width: 50%;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      height: 100%;
      align-items: center;
      justify-content: center;

      .loginTitle {
        color: #e95104;
        font-size: 48px;
        font-weight: bold;
        align-self: flex-start;
        margin-left: 80px;
        margin-top: 40px;
      }
      .login-form {
        margin-top: 70px;
        margin-bottom: 70px;
        padding: 10px 0;
        width: 70%;
      }
    }
  }
}
</style>

